class SocialLinks {
  static VK = 'https://vk.com/robuxpier'
  static DISCORD_RU = 'https://discord.gg/robuxpier'
  static DISCORD_EN = 'https://discord.gg/zckunR3EnD'
  static TELEGRAM = 'https://t.me/robuxpier'

  static getValues () {
    return [this.VK, this.DISCORD_RU, this.DISCORD_EN, this.TELEGRAM]
  }
}

module.exports = SocialLinks
