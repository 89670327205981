import SocialLinks from '@/enums/SocialLinks'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      SocialLinks
    }
  },
  computed: {
    ...mapGetters(['language']),
    isRussian () {
      return this.language === 'ru'
    },
    vkUrl () {
      return SocialLinks.VK
    },
    discordUrl () {
      return this.isRussian ? SocialLinks.DISCORD_RU : SocialLinks.DISCORD_EN
    },
    tgUrl () {
      return SocialLinks.TELEGRAM
    }
  }
}
