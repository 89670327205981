export default {
  data () {
    return {
      activeIndex: 0,
      dots: null,
      sliderItem: null,
      sliderLenta: null,
      LENGTH: null,
      sliderSpeed: 0.055,
      count: 0,
      activeSlideIndex: 0
    }
  },
  methods: {
    changeSlider (index) {
      this.sliderLenta.style.transform = `translateX(${-index * this.sliderItem.clientWidth}px)`
      this.changeDote(index)
      this.activeSlideIndex = index
    },
    changeDote (index) {
      this.dots.forEach(dote => {
        dote.classList.remove('active')
        if (Number(dote.dataset.index) === index) {
          dote.classList.add('active')
        }
      })
    },
    checkEnd (activeSlideIndex) {
      if (activeSlideIndex + 1 >= this.LENGTH) this.changeSlider(0)
      else this.changeSlider(activeSlideIndex + 1)
    }
  },
  mounted () {
    this.dots = document.querySelectorAll('.circle')
    this.sliderItem = document.querySelector('.slider__wrapper-item')
    this.sliderLenta = document.querySelector('.slider__wrapper-lenta')
    this.LENGTH = document.querySelectorAll('.slider__wrapper-item').length
    this.sliderSpeed = 0.055
    setInterval(() => {
      if (this.count < 100) {
        this.count += this.sliderSpeed
      } else {
        this.count = 0
        this.activeSlideIndex = Number(document.querySelector('.circle.active')?.dataset.index)
        this.checkEnd(this.activeSlideIndex)
      }
    }, 1)
    this.dots.forEach(dote => {
      dote.addEventListener('click', (event) => {
        const index = Number(event.target.dataset.index)
        this.count = 0
        this.changeSlider(index)
      })
    })
  }
}
