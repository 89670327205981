<template>
  <div class="apple">
    <div class="apple-form">
      <div class="apple-form__logo">
        <img src="./../../assets/images/apple/yakor.png" alt="logo">
      </div>
      <p>Сайт находится на обновлении! <br>Пожалуйста ожидайте!</p>
      <div class="apple-form__input">
        <input type="text" placeholder="Введите код" v-model="notApple" @keyup.enter="eatApple">
      </div>
      <div class="apple-form__timer" v-text="timer" />
      <div class="apple-form__button">
        <button @click="eatApple">Подтвердить</button>
      </div>
    </div>
  </div>
</template>
<script>
import './apple.scss'

export default {
  name: 'Apple',
  data () {
    return {
      apple: 'sevawork',
      notApple: '',
      timer: ''
    }
  },
  methods: {
    eatApple () {
      if (this.apple === this.notApple) {
        localStorage.setItem('apple', 'apple have eaten')
        this.$router.push('/')
      }
    },
    updateTime () {
      const now = new Date()
      const endTime = new Date()

      // Устанавливаем часы и минуты
      endTime.setHours(17)
      endTime.setMinutes(30)
      endTime.setSeconds(0) // Устанавливаем секунды на 0

      if (now.getTime() >= endTime) {
        this.timer = ''
      } else {
        const remainingTimeMs = endTime - now.getTime()
        const hours = Math.floor((remainingTimeMs / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((remainingTimeMs / (1000 * 60)) % 60)
        const seconds = Math.floor((remainingTimeMs / 1000) % 60)
        const hoursFormatted = hours.toString().padStart(2, '0')
        const minutesFormatted = minutes.toString().padStart(2, '0')
        const secondsFormatted = seconds.toString().padStart(2, '0')

        this.timer = `${hoursFormatted} ч. ${minutesFormatted} мин. ${secondsFormatted} сек.`
      }
    }
  },
  created () {
    this.updateTime()
    setInterval(() => {
      this.updateTime()
    }, 1000)
  }
}
</script>
