<template>
  <header class="header">
    <div class="burger" @click="$store.state.isOpenBurgerMenu = true">
      <svg width="36" height="28" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="-0.000976562" y="1" width="26" height="4" rx="2" fill="white"/>
        <rect x="31.999" y="1" width="4" height="4" rx="2" fill="#CDD6F6"/>
        <rect x="-0.000976562" y="24" width="4" height="4" rx="2" fill="#CDD6F6"/>
        <rect y="12.4545" width="36" height="4" rx="2" fill="white"/>
        <rect x="9.99902" y="24" width="26" height="4" rx="2" fill="white"/>
      </svg>
    </div>
    <router-link to="/" class="header__logo">
      <Logo />
    </router-link>
    <nav class="nav">
      <router-link exact-active-class="active" to="/" class="nav-item">{{ $t('navigation.home') }}</router-link>
      <router-link exact-active-class="active" to="/transfer" class="nav-item" >
        <span @click="goToTransfer">{{ $t('navigation.buyRobux') }}</span>
      </router-link>
    </nav>
    <div class="nav-socials">
      <a :href="vkUrl" target="_blank" class="nav-socials__block" v-if="$store.getters.language == 'ru'">
        <img :src="require('@/assets/images/help/vk.svg')" alt="">
      </a>
      <a :href="tgUrl" target="_blank" class="nav-socials__block">
        <img :src="require('@/assets/images/help/telegram.svg')" alt="">
      </a>
      <a :href="discordUrl" target="_blank" class="nav-socials__block">
        <img :src="require('@/assets/images/help/dis.svg')" alt="">
      </a>
    </div>

    <Selectables class="nav-selectables-pc" />

    <Button
      v-if="!isAuth"
      class="header-btn"
      left-icon="/Home/user.svg"
      @click="$store.commit('stateSignIn')"
    >
      {{ $t('navigation.lk') }}
    </Button>
    <div class="header-icon" v-if="!isAuth" @click="$store.commit('stateSignIn')">
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.1978 14.9931C20.5771 13.9138 21.5839 12.4338 22.0781 10.759C22.5723 9.0842 22.5293 7.29786 21.9551 5.64849C21.3808 3.99913 20.304 2.56876 18.8743 1.55639C17.4446 0.544027 15.7332 0 13.9782 0C12.2231 0 10.5117 0.544027 9.082 1.55639C7.65232 2.56876 6.57546 3.99913 6.00126 5.64849C5.42705 7.29786 5.38405 9.0842 5.87822 10.759C6.3724 12.4338 7.37918 13.9138 8.75851 14.9931C6.395 15.9348 4.33276 17.4967 2.79164 19.5123C1.25051 21.528 0.288277 23.9217 0.00750971 26.4384C-0.0128137 26.6222 0.00345293 26.8081 0.0553805 26.9856C0.107308 27.1631 0.19388 27.3287 0.310153 27.4729C0.544977 27.7642 0.886526 27.9507 1.25966 27.9916C1.6328 28.0324 2.00696 27.9241 2.29983 27.6906C2.5927 27.457 2.78029 27.1174 2.82134 26.7463C3.13027 24.0111 4.44166 21.485 6.50494 19.6507C8.56823 17.8163 11.2388 16.8022 14.0063 16.8022C16.7738 16.8022 19.4444 17.8163 21.5076 19.6507C23.5709 21.485 24.8823 24.0111 25.1913 26.7463C25.2295 27.0901 25.3945 27.4076 25.6543 27.6376C25.9141 27.8675 26.2504 27.9937 26.5982 27.9916H26.7529C27.1217 27.9494 27.4588 27.7639 27.6907 27.4756C27.9226 27.1873 28.0306 26.8195 27.991 26.4524C27.7089 23.9286 26.7415 21.5287 25.1924 19.5099C23.6434 17.4912 21.5711 15.9298 19.1978 14.9931ZM13.9782 13.9996C12.8651 13.9996 11.7771 13.6714 10.8516 13.0564C9.92614 12.4414 9.20483 11.5673 8.77888 10.5446C8.35294 9.52197 8.24149 8.39664 8.45864 7.31097C8.67578 6.22531 9.21176 5.22806 9.99881 4.44533C10.7858 3.66261 11.7886 3.12957 12.8803 2.91362C13.9719 2.69766 15.1034 2.8085 16.1318 3.23211C17.1601 3.65571 18.039 4.37306 18.6574 5.29345C19.2758 6.21383 19.6058 7.29591 19.6058 8.40285C19.6058 9.88721 19.0129 11.3108 17.9575 12.3604C16.9021 13.41 15.4707 13.9996 13.9782 13.9996Z" fill="white"/>
      </svg>
    </div>
    <div class="auth__wrapper" v-else>
      <div class="user">
        <div class="user-border"></div>
        <div class="user-wrapper">
          <div
            class="user-wrapper__avatar"
            @click="openUserMenu"
          >
            <img
              v-if="$store.state.account.isVk"
              :src="$store.state.account.avatarUrl"
              alt=""
            >
            <img
              v-if="$store.state.account.isVk === 0"
              :src="require(`../../assets/images/navigation/avatar-${$store.state.account.avatarId}.png`)"
              alt=""
            >
          </div>
          <div class="user-wrapper__block">
            <div class="user-wrapper__block-content">
              <p class="user-wrapper__block-nick">{{ $store.state.account.username }}</p>
              <div class="user-wrapper__block-total">
                <p class="user-wrapper__block-total__balance">{{ $store.getters.balance.toFixed(2) }} {{ $store.getters.currencySign }}</p>
                <router-link to="/payment" class="user-wrapper__block-total__add">
                  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.11894 6.848L11.4436 6.85304C11.6332 6.85304 11.8151 6.77772 11.9491 6.64364C12.0832 6.50957 12.1585 6.32773 12.1585 6.13813C12.1585 5.94852 12.0832 5.76668 11.9491 5.63261C11.8151 5.49854 11.6332 5.42322 11.4436 5.42322L7.11894 5.42826L7.12397 1.10357C7.12397 0.913961 7.04865 0.732121 6.91458 0.59805C6.78051 0.463979 6.59867 0.388659 6.40906 0.388659C6.21946 0.388659 6.03762 0.463979 5.90355 0.59805C5.76947 0.732122 5.69415 0.913961 5.69415 1.10357L5.69919 5.42826L1.3745 5.42322C1.28051 5.42284 1.18737 5.44106 1.10046 5.47686C1.01355 5.51265 0.934587 5.56529 0.868124 5.63175C0.801662 5.69822 0.749017 5.77718 0.713226 5.86409C0.677435 5.951 0.659207 6.04414 0.659592 6.13813C0.659207 6.23212 0.677434 6.32526 0.713226 6.41217C0.749017 6.49908 0.801662 6.57804 0.868124 6.6445C0.934587 6.71097 1.01355 6.76361 1.10046 6.7994C1.18737 6.83519 1.28051 6.85342 1.3745 6.85304L5.69919 6.848L5.69415 11.1727C5.69377 11.2667 5.712 11.3598 5.74779 11.4467C5.78358 11.5336 5.83622 11.6126 5.90269 11.6791C5.96915 11.7455 6.04811 11.7982 6.13502 11.834C6.22193 11.8698 6.31507 11.888 6.40906 11.8876C6.50305 11.888 6.59619 11.8698 6.6831 11.834C6.77001 11.7982 6.84897 11.7455 6.91544 11.6791C6.9819 11.6126 7.03454 11.5336 7.07034 11.4467C7.10613 11.3598 7.12435 11.2667 7.12397 11.1727L7.11894 6.848Z" fill="#040527"/>
                  </svg>
                </router-link>
              </div>
            </div>
            <div class="user-wrapper__block-arrow"
                 v-click-outside="() => isOpenUserDropMenu = false"
                 :class="{ openMenu: isOpenUserDropMenu }" @click="isOpenUserDropMenu = !isOpenUserDropMenu"
            >
              <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.18 0.616681C13.9218 0.360049 13.5726 0.216003 13.2086 0.216003C12.8446 0.216003 12.4953 0.360049 12.2372 0.616681L7.29058 5.49438L2.41289 0.616681C2.15472 0.360049 1.8055 0.216003 1.44148 0.216003C1.07746 0.216003 0.728238 0.360049 0.470075 0.616681C0.340928 0.744772 0.238421 0.897167 0.168468 1.06507C0.098515 1.23298 0.0625 1.41308 0.0625 1.59498C0.0625 1.77687 0.098515 1.95697 0.168468 2.12488C0.238421 2.29278 0.340928 2.44518 0.470075 2.57327L6.31229 8.41548C6.44038 8.54463 6.59277 8.64714 6.76068 8.71709C6.92859 8.78704 7.10869 8.82306 7.29058 8.82306C7.47248 8.82306 7.65257 8.78704 7.82048 8.71709C7.98839 8.64714 8.14078 8.54463 8.26888 8.41548L14.18 2.57327C14.3091 2.44518 14.4116 2.29278 14.4816 2.12488C14.5515 1.95697 14.5876 1.77687 14.5876 1.59498C14.5876 1.41308 14.5515 1.23298 14.4816 1.06507C14.4116 0.897167 14.3091 0.744772 14.18 0.616681Z" fill="white"/>
              </svg>
            </div>
          </div>
        </div>
        <div
          class="user-dropdown"
          :class="{ openDropDown: isOpenUserDropMenu }" @click="isOpenUserDropMenu = !isOpenUserDropMenu"
        >
          <ul>
            <li><router-link to="/history">{{ $t('navigation.dropdown.history') }}</router-link></li>
            <li v-if="$store.state.account.isVk !== 1"><a href="#" @click="$store.commit('stateChangePassword')">{{ $t('navigation.dropdown.changePassword') }}</a></li>
            <li @click="copyId"><a href="#">{{ $t('navigation.dropdown.copyId') }}</a></li>
            <li @click="$store.dispatch('logOut')"><a class="exit" href="#">{{ $t('navigation.dropdown.logout') }}</a></li>
          </ul>
        </div>
      </div>
      <div class="auth__wrapper__balance">
        <p>{{ $store.getters.balance.toFixed(2) }} {{ $store.getters.currencySign }}</p>
        <p class="add">
          <router-link to="/payment">
            <Icon name="/SignIn/plus.svg" />
          </router-link>
        </p>
      </div>
    </div>
  </header>
</template>

<script>
import './navigation.scss'
import Selectables from '@/components/Navigation/Selectables/Selectables.vue'
import Logo from '@/components/Logo/Logo.vue'
import Button from '@/components/Base/Button/Button.vue'
import { mapGetters } from 'vuex'
import Icon from '@/components/Base/Icon/Icon.vue'
import { clickOutside } from '@/directives/clickOutside'
import social from '@/mixins/social'
export default {
  name: 'Navigation',
  mixins: [social],
  components: {
    Icon,
    Button,
    Selectables,
    Logo
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      isOpenUserDropMenu: false,
      visibleAdaptiveBalance: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    isRussian () {
      return this.language === 'ru'
    },
    isAuth () {
      return this.$store.state.isAuth
    }
  },
  methods: {
    openUserMenu () {
      if (document.body.clientWidth <= 965) this.$store.state.isOpenUserDropMenu = true
    },
    copyId () {
      navigator.clipboard.writeText(this.$store.state.account.userId)
    },
    goToTransfer () {
      if (this.$route.path === '/transfer') window.location.replace('/transfer')
    }
  }
}
</script>
