<template>
  <Modal
    icon="office-bag"
    :title="$t('modals.partnership.title')"
    @close="$emit('close')"
  >
    <template #text>
      <div class="partnership-modal__text" v-html="$t('modals.partnership.text')" />
    </template>
    <template #footer>
      <a href="https://t.me/rbx_manager" target="_blank">
        <Button right-icon="/help/telegram.svg">
          {{ $t('modals.partnership.submit') }}
        </Button>
      </a>
    </template>
  </Modal>
</template>

<script>
import './partnership.scss'
import Modal from '@/components/Base/Modal/modal.vue'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'PartnershipModal',
  components: {
    Button,
    Modal
  }
}
</script>
