<template>
  <div class="recent-accounts">
    <div
      v-for="nickname in uniqueNamesList"
      class="recent-accounts__item"
      :key="`recent-${nickname}`"
      @click="$emit('onSelect', nickname)"
    >
      <span :class="{ active : robloxNickname === nickname }">
        {{ nickname }}
      </span>
    </div>
  </div>
</template>

<script>
import './recentAccounts.scss'
export default {
  name: 'RecentAccounts',
  props: {
    robloxNickname: {
      type: String
    }
  },
  data () {
    return {
      orders: []
    }
  },
  computed: {
    uniqueNamesList () {
      const uniqueNamesSet = new Set(this.orders?.map(order => order.username))
      return [...uniqueNamesSet].slice(0, 3)
    }
  },
  mounted () {
    const requestHeaders = new Headers({
      Authorization: `Bearer ${this.$cookies.get('access_token')}`
    })

    const requestParams = {
      method: 'GET',
      headers: requestHeaders
    }

    fetch('https://transfer.backend-pier.com/get_history?count=7&page=1', requestParams)
      .then((res) => res.json())
      .then((data) => {
        if (data.status) {
          this.orders = data.orders
        }

        this.loading = false
      })
      .catch(err => console.log(err))
  }
}
</script>
