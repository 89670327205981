<template>
  <Modal
    icon="fire"
    :title="$t('modals.promocode.title')"
    @close="$emit('close')"
  >
    <template #text>
      <div class="social-modal__text">{{ $t('modals.promocode.text') }}</div>
    </template>
    <template #footer>
      <div class="social-modal__footer">
        <a href="https://t.me/robuxpier" target="_blank">
          <Button
          >
            <img :src="require('@/assets/images/help/telegram.svg')" alt="">
          </Button>
        </a>
        <a href="https://vk.com/robuxpier" target="_blank">
          <Button
          >
            <img :src="require('@/assets/images/help/vk.svg')" alt="">
          </Button>
        </a>
        <a href="https://discord.gg/robuxpier" target="_blank">
          <Button
          >
            <img :src="require('@/assets/images/help/dis.svg')" alt="">
          </Button>
        </a>
      </div>
    </template>
  </Modal>
</template>

<script>
import './promocode.scss'
import Modal from '@/components/Base/Modal/modal.vue'
import Button from '@/components/Base/Button/Button.vue'

export default {
  name: 'PromocodeModal',
  components: {
    Button,
    Modal
  }
}
</script>
